<template>
    <NebulaIcon
        class="subject-icon"
        :symbol-id="subjectIcon"
        size="s"
    />
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import subjectIconMap from '@/data/subjectIconMap';

export default {
    name: 'SubjectIcon',
    components: {
        NebulaIcon,
    },
    props: {
        subject: {
            type: String,
            required: true,
        },
    },
    computed: {
        subjectIcon() {
            return subjectIconMap[this.subject];
        },
    },
};
</script>

<style lang="stylus">
.subject-icon {
    fill: $nebula-color-platform-interface-800;
    margin-inline-end: $nebula-space-1x;
}
</style>
