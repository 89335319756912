function getOrdinalSuffix(number) {
    const tens = number % 10;
    const hundreds = number % 100;

    if (tens === 1 && hundreds !== 11) {
        return `${number}st`;
    }
    if (tens === 2 && hundreds !== 12) {
        return `${number}nd`;
    }
    if (tens === 3 && hundreds !== 13) {
        return `${number}rd`;
    }
    return `${number}th`;
}

export default getOrdinalSuffix;
