<template>
    <div>
        <div v-if="schoolName">{{ displaySchool }}</div>
        <div v-if="location" class="request-school__location">{{ location }}</div>
    </div>
</template>

<script>
const SCHOOL_MAX_LENGTH = 21;
export default {
    name: 'RequestSchool',
    props: {
        schoolName: String,
        location: String,
    },
    computed: {
        displaySchool() {
            const { schoolName: school } = this;
            return school && school.length > SCHOOL_MAX_LENGTH ? `${school.slice(0, SCHOOL_MAX_LENGTH)}...` : school;
        },
    },
};
</script>

<style lang="stylus">
.request-school {
    &__location {
        nebula-text-caption();
    }
}
</style>
