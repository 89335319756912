<template>
    <NebulaBadge class="request-status" size="s" :color="color">{{ display }}</NebulaBadge>
</template>

<script>
import { NebulaBadge } from '@discoveryedu/nebula-components';

import requestStatusMap from '@/data/requestStatusMap';
import application from '@/mixins/requests/application';

import {
    draft,
    scheduled,
    completed,
    canceled,
    actionRequired,
    awaitingConfirmation,
    pendingMatches,
    expired,
} from '@/constants';

export default {
    name: 'RequestStatus',
    components: {
        NebulaBadge,
    },
    mixins: [application],
    props: {
        status: {
            type: String,
            default: draft,
        },
        requestId: String,
        date: Number,
    },
    data() {
        return {
            statusMap: {
                [draft]: {
                    color: 'dark-gray',
                    display: 'Draft',
                },
                [scheduled]: {
                    color: 'green',
                    display: 'Scheduled',
                },
                [canceled]: {
                    color: 'red',
                    display: 'Canceled',
                },
                [completed]: {
                    color: 'light-gray',
                    display: 'Completed',
                },
                [actionRequired]: {
                    color: 'orange',
                    display: 'Action Required',
                },
                [awaitingConfirmation]: {
                    color: 'light-gray',
                    display: 'Awaiting Confirmation',
                },
                [pendingMatches]: {
                    color: 'light-gray',
                    display: 'Pending Matches',
                },
                [expired]: {
                    color: 'light-gray',
                    display: 'Expired',
                },
                'accepting-applications': {
                    color: 'light-gray',
                    display: 'Accepting Applications',
                },
            },
        };
    },
    computed: {
        mappedStatus() {
            let { status } = this;

            if (!status) {
                status = 'draft';
            }

            const handledRole = Object.keys(requestStatusMap).includes(this.appMode);
            if (!handledRole) {
                return status;
            }

            const handledStatus = Object.keys(requestStatusMap[this.appMode]).includes(status);
            if (!handledStatus) {
                return status;
            }

            if (this.applicationOpen) {
                return awaitingConfirmation;
            }

            return requestStatusMap[this.appMode][status];
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        color() {
            return this.statusMap[this.mappedStatus].color;
        },
        display() {
            return this.statusMap[this.mappedStatus].display;
        },
    },
};
</script>

<style lang="stylus">
.request-status {
    white-space: nowrap;
    .nebula-badge--fill {
        &.nebula-badge--orange {
            --nebula-badge-background-color: $nebula-color-feedback-warning-100;
            --nebula-badge-color: $nebula-color-feedback-warning-700;
        }
        &.nebula-badge--red {
            --nebula-badge-background-color: $nebula-color-feedback-error-100;
            --nebula-badge-color: $nebula-color-feedback-error-600;
        }
        &.nebula-badge--green {
            --nebula-badge-background-color: $nebula-color-feedback-success-100;
            --nebula-badge-color: $nebula-color-feedback-success-800;
        }
        &.nebula-badge--dark-gray {
            --nebula-badge-background-color: $nebula-color-platform-neutral-300;
            --nebula-badge-color: $nebula-color-platform-neutral-1000;
        }
        &.nebula-badge--light-gray {
            --nebula-badge-background-color: $nebula-color-platform-interface-300;
            --nebula-badge-color: $nebula-color-platform-interface-1000;
        }
    }
}
</style>
