import {
    roles,
    actionRequired,
    awaitingConfirmation,
    pendingMatches,
} from '@/constants';

// 'accepting-applications' is not a string that would ever be stored in the BE
const acceptingApplications = 'accepting-applications';

// Values stored on the backend are from the perspective of the educator
// these will display differently for other user types
const requestStatusMap = {
    [roles.EMPLOYEE_ROLE]: {
        [actionRequired]: acceptingApplications,
        [awaitingConfirmation]: actionRequired,
        [pendingMatches]: acceptingApplications,
    },
    [roles.ORGADMIN_ROLE]: {
        [actionRequired]: acceptingApplications,
        [awaitingConfirmation]: acceptingApplications,
        [pendingMatches]: acceptingApplications,
    },
    [roles.SCHOOLADMIN_ROLE]: {
        [actionRequired]: pendingMatches,
        [awaitingConfirmation]: pendingMatches,
        [pendingMatches]: pendingMatches,
    },
};

export default requestStatusMap;
