import createPayload from '@/mixins/data/createPayload';
import { roles, actionRequired, pendingMatches } from '@/constants';

const applyableStatuses = [actionRequired, pendingMatches];

export default {
    mixins: [createPayload],
    computed: {
        request() {
            return this.$store.state.page.request;
        },
        userApplication() {
            // eslint-disable-next-line no-underscore-dangle
            const _requestId = this.request.requestId || this.requestId;
            return this.$store.state.page.applications.find((application) => application.requestId === _requestId);
        },
        applicationOpen() {
            // if request is not in application list OR has been declined, no application
            // or is in a status that cannot accept applications
            // eslint-disable-next-line no-underscore-dangle
            const _status = this.request.status || this.status;
            if (!this.userApplication || this.applicationDeclined || !applyableStatuses.includes(_status)) {
                return false;
            }

            if (this.directRequested && !this.directRequestDeclined) {
                return false;
            }

            // if not direct requested
            return !this.directRequested;
        },
        applicationDeclined() {
            return this.userApplication && this.userApplication.declined && !this.userApplication.directRequest;
        },
        directRequestDeclined() {
            // return this.userApplication && this.userApplication.declined && this.userApplication.directRequest;
            return this.userApplication && this.userApplication.directRequest && this.applicationPossible;
        },
        applicationPossible() {
            // eslint-disable-next-line no-underscore-dangle
            const _status = this.request.status || this.status;
            return applyableStatuses.includes(_status);
        },
        directRequested() {
            return this.userApplication && this.userApplication.directRequest;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
    },
    methods: {
        async getApplications() {
            if (this.appMode === roles.EMPLOYEE_ROLE) {
                const payload = await this.createPayload();
                await this.$store.dispatch('getApplications', payload);
            }
        },
    },
};
